import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Branding from '../../../src/assets/images/thinking.png'
import WebDesign from '../../../src/assets/images/webDesign.png'
import Ads from '../../../src/assets/images/promotion.png'
import ProductionHouse from '../../../src/assets/images/video.png'
import Select from '../../../src/assets/images/select.png'
const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  // const innerClasses = classNames(
  //   'features-split-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider'
  // );
  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );


  const sectionHeader = {
    title: 'How Can We Assist You',
    paragraph: 'We provide the following services.'
  };
  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      id='Services'
    >
  
  <div className="container">
        <div className={innerClasses}>
          <br/>
          <br/><br/>
          <SectionHeader data={sectionHeader} className="center-content" style={{marginTop:25}} />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" >
              <div className="tiles-item-inner1" style={{backgroundColor:'white',borderRadius:7}}>
                <div className="features-tiles-item-header" style={{display:'flex'}}>
                  
                    {/* <Image
                     src={Branding}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} /> */}
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{margin:'auto',backgroundColor:'#356793',width:65,height:65,borderRadius:45,display:'flex'}}>
                  <img src={Branding} style={{width:30,height:30,margin:'auto'}} alt="Branding"/>
                  </div>
                
               
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8" style={{marginTop:20}}>
                  Branding
                    </h6>
                  <p className="m-0 text-sm">
                  We create thoughtful and creative brand identities that strategically align with your values and vision. 
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner1"  style={{backgroundColor:'white',borderRadius:7}}>
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{margin:'auto',backgroundColor:'#356793',width:65,height:65,borderRadius:45,display:'flex'}}>
                  <img src={WebDesign} style={{width:30,height:30,margin:'auto'}} alt="WebDesign"/>
                  </div>
                
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">
                  Website Design & Development
                    </h6>
                  <p className="m-0 text-sm">
   Websites
                  have the power to effectively share your brand story and build trust with your audience.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner1" style={{backgroundColor:'white',borderRadius:7}}>
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{margin:'auto',backgroundColor:'#356793',width:65,height:65,borderRadius:45,display:'flex'}}>
                  <img src={Ads} style={{width:30,height:30,margin:'auto'}} alt="Ads"/>
                  </div>
                
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">
                  
                  Campaigning & Advertising
                    </h6>
                  <p className="m-0 text-sm">
                  A content
partner can help you keep pace by publishing
content or deciding what content 
to produce.
                                           </p>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner1" style={{backgroundColor:'white',borderRadius:7}}>
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{margin:'auto',backgroundColor:'#356793',width:65,height:65,borderRadius:45,display:'flex'}}>
                  <img src={ProductionHouse} style={{width:30,height:30,margin:'auto'}} alt="ProductionHouse"/>
                  </div>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">
                  
                  Production House
                    </h6>
                  <p className="m-0 text-sm">
                  Our approach combines expertise and passion to make
videos that engage, inspire people to act.
                    </p>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner1" style={{backgroundColor:'white',borderRadius:7}}>
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8" style={{margin:'auto',backgroundColor:'#356793',width:65,height:65,borderRadius:45,display:'flex'}}>
                  <img src={Select} style={{width:30,height:30,margin:'auto'}} alt="Select"/>
                  </div>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">
                  
                  Digital Media Solutions Agency & Consultancy
                    </h6>
                  <p className="m-0 text-sm">
                    Our team offers a  process that helps comapnies position themselves in the digital space.
            

                    </p>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
        
      
      {/* <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" style={{marginTop:25}} />
          <div className={splitClasses}>

            <div className="split-item">
              <div  className="split-item-content center-content-mobile reveal-from-left"  data-reveal-container=".split-item">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  <img src={Branding} style={{width:30,height:30}} alt="Branding"/>
                  </div>
                
                <h5 className="mt-0 mb-12" >
                 Branding
                  </h5>
                <p className="m-0" style={{fontSize:17}}> 
                We create thoughtful brand identities that strategically align with your values and vision. Our holistic 
                design process starts with discovering your brand story and establishing a creative direction that works for you.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
             
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                <img src={WebDesign} style={{width:30,height:30}} alt="WebDesign"/>
                  </div>
                <h5 className="mt-0 mb-12">
                  Website Design & Development
                  </h5>
                <p className="m-0" style={{fontSize:17}}>
                  A strategically designed website doesn't just look pretty. It's your most important asset. Websites
                  have the power to legitimize your business, effectively share your brand story and build trust with your audience.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                <img src={Ads} style={{width:30,height:30}} alt="Ads"/>
                  </div>
                <h5 className="mt-0 mb-12">
                 Campaigning & Advertising
                  </h5>
                <p className="m-0" style={{fontSize:17}}>
                The world moves fast these days, and a content
partner can help you keep pace by automating various
aspects of advertising, whether that's publishing
content or deciding what content 
to produce.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                <img src={ProductionHouse} style={{width:30,height:30}} alt="Production House"/>
                  </div>
                <h5 className="mt-0 mb-12">
                 Production House
                  </h5>
                <p className="m-0" style={{fontSize:17}}>
                Our approach combines expertise and passion with a 
deep understanding of you and your audience to make
videos that engage, inspire people to act.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                  <img src={ProductionHouseImage} style={{width:450,height:180}} alt="Production House Image" />
                {/* <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} /> */}
              {/* </div>
              
            </div> */}



            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                <img src={Select} style={{width:30,height:30}} alt="Select" />
                  </div>
                <h5 className="mt-0 mb-12">
                Digital Media Solutions Agency & Consultancy
                  </h5>
                <p className="m-0" style={{fontSize:17}}>
                  
Our team guides companies
 when they seek to position themselves 
in the digital space and offer personalized
 assessments through a process that includes 
the evaluation, optimization, and development 
of solutions in their businesses.  
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div> */}
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;