import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import DigitalPicture3 from '../../assets/images/background.jpg'
import DigitalPicture32 from '../../assets/images/bground.jpg'
import { HashLink } from 'react-router-hash-link';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }

  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  // const innerClasses = classNames(
  //   'hero-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider'
  // );

  return (
    <section
      {...props}
      className={outerClasses}
      id='#Services'
    
    >
   
      <div className="alNaserClass" >
      <text className="bigText">We Are a Media Production </text><br/><br className="onlyOnce" />
      <text className="bigText2">Agency</text><br/><br/>
      <text className="secondText">Al Naser Media Production is a digital company that helps legal entities of all sizes and types master the digital arena. We specialize in content visualization, social media communication strategy and unconventional productions.</text><br/>
      <br/><br/>
      <HashLink smooth to='/#Services' >
      <button className="buttonTrans">Get Started Today</button>
      </HashLink>
      </div>
    
      <img src={DigitalPicture3} className="DigitalPictures" style={{width:"100%"}} alt="background1"/>
   <img src={DigitalPicture32} className="DigitalPictures32" style={{width:"100%",height:670}} alt="background2"/> 
      {/* <div style={{position:'relative'}}>
        <div>
 
      <div style={{position:'absolute',width:'80%',height:'20%',left:'10%',top:'40%'}}>
      <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Landing template for <span className="text-color-primary">startups</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.
                </p>
            </div>
      
        </div>
      </div>
</div> */}
      {/* <div className="container-sm">
    
        <div className={innerClasses}> 
      
         <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Landing template for <span className="text-color-primary">startups</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.
                </p>
            </div>
            
      </div>  
          <div className="hero-figure reveal-from-bottom illustration-element-01" width="100%" data-reveal-value="20px" data-reveal-delay="800">
         
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
          </div> 
      </div> 
      </div>   */}
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;