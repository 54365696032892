import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>

    {/* Includes Everything besides header and footer */}
    {/* Part 1 -> Background + picture */}
      <Hero className="illustration-section-01" />
    {/* Part 3 -> More Options */}
      <FeaturesSplit invertMobile  imageFill className="illustration-section-02" />
    {/* Part 4 -> Customer test.. */}
      {/* <Testimonial topDivider /> */}
       {/* Part 2 -> Benefits */}
       <FeaturesTiles topDivider />
    {/* Part 5 -> Email Part */}
      <Cta split />
        
    </>
  );
}

export default Home;