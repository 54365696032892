import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';
import AlNaserLogo from '../../../assets/images/Logo.png'
const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        
           <Image
            src={AlNaserLogo}
            alt="Open"
            width={215}
            // height={115}
            
            // style={{marginTop:20}}
            />      
      </h1>
    </div>
  );
}

export default Logo;