import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterSocial from './partials/FooterSocial';
import HomeIcon from '@material-ui/icons/Home'
import PhoneIcon from '@material-ui/icons/LocalPhone'
import MailIcon from '@material-ui/icons/Mail'
const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        {/* <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}> */}
          <div className="footer-top space-between text-xxs">
            <Logo />
            <div>
           
              <div>
              <div style={{height:28}}>
               <HomeIcon style={{width:20,height:20,marginBottom:-4}} />
              <text style={{marginLeft:12}}>Address </text><br/>
              </div>
              <div style={{height:33}}>
              <text >Nad Al Sheba, Dubai, United Arab Emirates</text>
              </div>
              </div>
              <div> 
                <div style={{height:28}}>
              <PhoneIcon style={{width:20,height:20,marginBottom:-4}} />  
              <text style={{marginLeft:12}}>Phone </text><br/>
              </div>
              <div style={{height:33}}>
              <text>+971 56 952 0006</text>
              </div>
              </div>
              <div>
                <div style={{height:28}}>
                <MailIcon style={{width:20,height:20,marginBottom:-4}}/>
              <text style={{marginLeft:12}}>Email </text><br/>
              </div>
              <text>Info@alnasermedia.com</text>
              </div>
            </div>
          </div>
          <FooterSocial />
      </div> 
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;